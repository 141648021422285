<template>
   <div class="pt-2 postition_parent" 
      style="background-color:transparent;">

      <div class="bgImage"
         :class = "{'blur-effect': blurBackground}"
         :style="{'background-image': backgroundImg?backgroundImg:'none'}">
      </div>

      <div
         :style="{'background-color': transparentColor}"
         class="layer-transparent">

      </div>

      <div class="pa-2 px-4"
         style="position:absolute;top:8px;left:12px;z-index:9;background-color:#00000033;border-radius:8px;">
         <div v-for="(widget ,index) in widgets" :key="index"
            style="text-align:left;">
            <div class="inline mr-2 pnLabel">{{widget.displayName}}</div>
            <div class="inline mr-2 pnValue">{{widget.value}}</div>
            <div class="inline mr-2 pnUnit">{{widget.unit}}</div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   props: ['modal', 'customStyle'],
   data () {
      return {
         widgets: [],
         backgroundImg: null,
         blurBackground: true,
         transparentColor: '#00000000',
      }
   },
   sockets: {
      reportLastValue: function (payload) {
         if(payload && payload.devCode == this.$root.devCode){
            this.records = payload.content

            console.log(this.records)
            this.widgets.forEach(widget => {
               let matchRec = this.records.find(rec => rec.reportName == widget.reportName)
               if (matchRec) {
                  widget.value = Math.round(10*matchRec.value)/10
                  widget.qos = matchRec.qos
                  widget.recAt = matchRec.recordDate
               }
            })
            this.$forceUpdate()
         }

      }
   },
   created () {
      if (this.modal && this.modal.widgets) {
         this.widgets = this.modal.widgets
      }

      if (this.customStyle) {
         if(this.customStyle.backgroundImg != undefined) this.backgroundImg = this.customStyle.backgroundImg
         if(this.customStyle.transparentColor != undefined) this.transparentColor = this.customStyle.transparentColor
         if(this.customStyle.blurBackground != undefined) this.blurBackground = this.customStyle.blurBackground
      }
   }
}
</script>

<style>
   .postition_parent {
      width: 100%;
      height: 100%;
      position: relative;
   }

   .blur-effect {
      filter: blur(1px);
      webkit-filter: blur(1px);
   }

   .bgImage {
      z-index:1;
      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
      background-size:cover;
   }

   .layer-transparent {
      z-index:2;
      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;
   }

   .pnLabel {
      line-height: 48px;
      font-size: 28px;
      font-weight: 500;
      text-align: left;
      color: #E0E0E0;
      min-width: 260px;
   }
   .pnValue {
      line-height: 48px;
      font-size: 44px;
      font-weight: 600;
      color: #FFFFFF;
      min-width: 90px;
      text-align: left;
   }
   .pnUnit {
      line-height: 36px;
      font-size: 16px;
      font-weight: 500;
      color: #CCCCCC;
      text-align: left;
   }

   .inline {
      display: inline-block;
      vertical-align: top;
   }
</style>